/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::-moz-selection {
  color: #fff;
  background: #1890ff;
}
::selection {
  color: #fff;
  background: #1890ff;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder {
  position: relative;
}
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-top .ant-notification-notice,
.ant-notification-bottom .ant-notification-notice {
  margin-right: auto;
  margin-left: auto;
}
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #1890ff;
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  opacity: 0;
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-notification-top,
.ant-notification-bottom {
  margin-right: 0;
  margin-left: 0;
}
.ant-notification-top .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-top .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationTopFadeIn;
}
.ant-notification-bottom .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottom .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationBottomFadeIn;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
@keyframes NotificationTopFadeIn {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes NotificationBottomFadeIn {
  0% {
    margin-bottom: -100%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn > a:only-child {
  color: currentcolor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: #fff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentcolor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active {
  color: #096dd9;
  border-color: #096dd9;
  background: #fff;
}
.ant-btn:active > a:only-child {
  color: currentcolor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #40a9ff;
  background: #40a9ff;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active {
  color: #fff;
  border-color: #096dd9;
  background: #096dd9;
}
.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #40a9ff;
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: transparent;
}
.ant-btn-ghost > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-ghost:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: #fff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active {
  color: #096dd9;
  border-color: #096dd9;
  background: #fff;
}
.ant-btn-dashed:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #1890ff;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover {
  background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;
}
.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
  background: #fff;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
  background: #fff;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}
.ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}
a.ant-btn-icon-only {
  vertical-align: -1px;
}
a.ant-btn-icon-only > .anticon {
  display: inline;
}
.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}
.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  background: transparent;
}
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn.ant-btn-background-ghost:active {
  color: #096dd9;
  border-color: #096dd9;
}
.ant-btn.ant-btn-background-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #d9d9d9;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #1890ff;
  border-color: #1890ff;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #096dd9;
  border-color: #096dd9;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 30px;
}
a.ant-btn-disabled {
  cursor: not-allowed;
}
a.ant-btn-disabled > * {
  pointer-events: none;
}
a.ant-btn-disabled,
a.ant-btn-disabled:hover,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
a.ant-btn-disabled > a:only-child,
a.ant-btn-disabled:hover > a:only-child,
a.ant-btn-disabled:focus > a:only-child,
a.ant-btn-disabled:active > a:only-child {
  color: currentcolor;
}
a.ant-btn-disabled > a:only-child::after,
a.ant-btn-disabled:hover > a:only-child::after,
a.ant-btn-disabled:focus > a:only-child::after,
a.ant-btn-disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #40a9ff;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #40a9ff;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}

.w1swd7y7{display:contents;}.w1swd7y7 .ant-btn-primary{background:#F95F00;font-size:16px;line-height:25px;box-shadow:none;color:#FFFFFF;}.w1swd7y7 .ant-btn-primary:focus{border-color:#F95F00;}.w1swd7y7 .ant-btn-primary:hover{border-color:#F95F00;}.w1swd7y7 .ant-btn-ghost{font-size:16px;line-height:25px;box-shadow:none;background:transparent;color:#F95F00;}.w1swd7y7 .ant-btn-ghost:focus{border-color:#F95F00;}.w1swd7y7 .ant-btn-ghost:hover{border-color:#F95F00;}
.s10m5k5i{border-color:var(--s10m5k5i-3);border-radius:var(--s10m5k5i-4);margin:var(--s10m5k5i-5);padding:var(--s10m5k5i-6);background:var(--s10m5k5i-7);color:var(--s10m5k5i-8);font-size:var(--s10m5k5i-9);font-weight:var(--s10m5k5i-10);min-width:var(--s10m5k5i-11);width:var(--s10m5k5i-12);min-height:var(--s10m5k5i-13);transition:opacity 0.5s;}var(--s10m5k5i-0) .s10m5k5i[disabled]{background-color:#C2C2C2;border:none;box-shadow:none !important;color:#FFFFFF;}.s10m5k5i:hover{opacity:0.8;}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton-active .ant-skeleton-title,
.ant-skeleton-active .ant-skeleton-paragraph > li,
.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton-active .ant-skeleton-input,
.ant-skeleton-active .ant-skeleton-image {
  position: relative;
  /* stylelint-disable-next-line property-no-vendor-prefix,value-no-vendor-prefix */
  z-index: 0;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton-active .ant-skeleton-title::after,
.ant-skeleton-active .ant-skeleton-paragraph > li::after,
.ant-skeleton-active .ant-skeleton-avatar::after,
.ant-skeleton-active .ant-skeleton-button::after,
.ant-skeleton-active .ant-skeleton-input::after,
.ant-skeleton-active .ant-skeleton-image::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-block {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }
  100% {
    transform: translateX(37.5%);
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.c1j5hjy6{display:inline-block;width:200px;max-width:var(--c1j5hjy6-0);cursor:pointer;transition:opacity 0.5s;}.c1j5hjy6 img{width:var(--c1j5hjy6-0) !important;height:var(--c1j5hjy6-0) !important;-o-object-fit:cover;object-fit:cover;border-radius:10px;margin-bottom:10px;}.c1j5hjy6 .ant-skeleton-image{width:200px;height:200px;border-radius:10px;}.c1j5hjy6:hover{opacity:0.8;}
.c1vyakym{margin:var(--c1vyakym-0);color:#00a0d2;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:14px;font-weight:bold;line-height:18px;word-break:break-all;}
.c1k0cf7u{margin:var(--c1k0cf7u-0);font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:14px;font-weight:bold;line-height:18px;color:#1B1B1B;word-break:break-all;}
.cxp6hg8{margin-bottom:0;font-size:12px;line-height:22px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:bold;line-height:20px;color:#1B1B1B;word-break:break-all;}
.c1fswnpe{margin-bottom:0;font-size:12px;line-height:22px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:bold;line-height:20px;color:#b6b6b6;word-break:break-all;}
.o1d78svk{border-radius:10px;overflow:hidden;position:relative;}.o1d78svk::after{content:"";height:56px;position:absolute;right:0;left:0;bottom:0;background:linear-gradient(180deg,rgba(27,27,27,0) 0%,#1b1b1b 100%);}
.o16isyx5{position:absolute;z-index:10;bottom:0;display:flex;width:100%;align-items:flex-end;padding:10px 10px 10px 15px;}
.ouprej5{font-size:12px;line-height:22px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:bold;color:#F5F5F5;margin-right:auto;}
.o1lzokr9{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:14px;font-weight:bold;line-height:18px;font-weight:500;color:#FFFFFF;line-height:22px;}
.o12fl6tc{font-size:16px;line-height:25px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;color:#FFFFFF;font-weight:bold;text-align:right;margin-left:4px;}
.f1w9761{display:flex;align-items:center;}
.c1ogzuq4{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:14px;line-height:22px;color:#1B1B1B;margin-bottom:10px;font-weight:500;word-break:break-all;}
.cblewx{font-size:12px;line-height:22px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:bold;margin:0;color:#b6b6b6;}
.h4i2eso{display:inline-flex;justify-content:center;width:66px;background:#00a0d2;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:12px;line-height:22px;color:#FFFFFF;border-radius:2px;}
.c1khbxnk{font-size:12px;line-height:22px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:bold;margin:0;color:#b6b6b6;}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb li:last-child {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb li:last-child a {
  color: rgba(0, 0, 0, 0.85);
}
li:last-child > .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-breadcrumb-rtl {
  direction: rtl;
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl > span {
  float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-top,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottom,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-dropdown-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-dropdown-placement-top > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 10px;
  box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}
.ant-dropdown-placement-top > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 9.41421356px;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
  transform: rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-title-content {
  flex: auto;
}
.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.ant-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #1890ff;
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #1890ff;
}
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading,
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn::before {
  display: block;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #1890ff;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title,
.ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu-item-danger.ant-menu-item {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff4d4f;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #bae7ff;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-overflow {
  display: flex;
}
.ant-menu-overflow-item {
  flex: none;
}
.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #1890ff;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6f7ff;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-title-content {
  transition: color 0.3s;
}
.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item a:hover {
  color: #1890ff;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item > .ant-badge a:hover {
  color: #1890ff;
}
.ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px 0 0;
}
.ant-menu-item-divider-dashed {
  border-style: dashed;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #1890ff;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #1890ff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu-item:focus-visible,
.ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #bae7ff;
}
.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 2px;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #1890ff;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #1890ff;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #1890ff;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #1890ff;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #1890ff;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #1890ff;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #1890ff;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  cursor: not-allowed;
}
.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #1890ff;
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #096dd9;
}
.ant-menu-dark .ant-menu-item:focus-visible,
.ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #096dd9;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #1890ff;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1890ff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  width: -moz-max-content;
  width: max-content;
  width: intrinsic;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-content {
  position: relative;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 14.3137085px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 14.3137085px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 14.3137085px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 14.3137085px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-tooltip-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(315deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(11px) rotate(225deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-pink .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}
.ant-tooltip-red .ant-tooltip-arrow-content::before {
  background: #f5222d;
}
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content::before {
  background: #fa541c;
}
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-orange .ant-tooltip-arrow-content::before {
  background: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content::before {
  background: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content::before {
  background: #faad14;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content::before {
  background: #13c2c2;
}
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-lime .ant-tooltip-arrow-content::before {
  background: #a0d911;
}
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}
.ant-tooltip-green .ant-tooltip-arrow-content::before {
  background: #52c41a;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}
.ant-tooltip-blue .ant-tooltip-arrow-content::before {
  background: #1890ff;
}
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
  background: #2f54eb;
}
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}
.ant-tooltip-purple .ant-tooltip-arrow-content::before {
  background: #722ed1;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}

.wal2ss6{background-color:#f5f5f5;min-height:58px;display:flex;padding-left:96px;padding-right:96px;align-items:center;}@media (max-width:1325px){.wal2ss6{padding-left:50px;padding-right:50px;}}@media (max-width:768px){.wal2ss6{padding-left:24px;padding-right:24px;}}
.b1jgf53g{width:100%;max-width:1440px;margin:auto;}.b1jgf53g a,.b1jgf53g span{color:#999;font-weight:700;font-size:14px;}@media (max-width:768px){.b1jgf53g a,.b1jgf53g span{font-size:12px;}}.b1jgf53g a:hover{color:#F95F00;}.b1jgf53g .ant-breadcrumb-separator{margin:0 13px;}@media (max-width:768px){.b1jgf53g .ant-breadcrumb-separator{margin:0 8px;}}

.c15lo3sr{cursor:pointer;display:inline-block;width:220px;height:200px;position:relative;}
.o2n4q0g{border-radius:10px;overflow:hidden;position:relative;}.o2n4q0g::after{content:"";position:absolute;height:81px;right:0;left:0;bottom:0;background:linear-gradient(180deg,rgba(27,27,27,0) 0%,#1b1b1b 100%);}
.alfrxv3{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:14px;font-weight:bold;line-height:18px;color:#F5F5F5;margin-bottom:5px;}
.ao2sgk9{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:14px;font-weight:bold;line-height:18px;color:#FFFFFF;font-weight:500;line-height:17px;margin-bottom:0;}
.a1uyo44l{width:220px;height:200px;-o-object-fit:cover;object-fit:cover;border-radius:10px;}
.t8cicms{position:absolute;bottom:0;z-index:2;margin:10px 15px 10px 10px;}

.c1xj8f11{display:flex;flex-direction:var(--c1xj8f11-0);background:#FFFFFF;border-radius:10px;max-width:1000px;padding:40px;}
.fc51usq{width:320px;height:320px;border-radius:10px;-o-object-fit:cover;object-fit:cover;}
.tlnu8vp{display:flex;flex-direction:column;margin-left:var(--tlnu8vp-0);margin-right:var(--tlnu8vp-1);flex:1 1 auto;}
.qineldj{display:flex;font-size:24px;font-weight:bold;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;line-height:34px;color:#1B1B1B;margin-bottom:20px;word-break:break-all;}.qineldj span{font-size:24px;font-weight:bold;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;line-height:34px;color:#009DD2;}
.t6wogy2{font-size:16px;line-height:30px;font-weight:bold;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;line-height:22px;color:#1B1B1B;word-break:break-all;margin-bottom:10px;}
.f83gwyc{font-size:16px;line-height:30px;font-weight:bold;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;line-height:22px;color:#F16122;word-break:break-all;margin-bottom:20px;}
.d1vpxl3k{font-size:16px;line-height:30px;font-weight:bold;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:500;color:#1B1B1B;word-break:break-all;}

.sp2ol3f{display:flex;justify-content:space-between;align-items:center;margin-bottom:10px;font-size:20px;line-height:140%;}
.s15a9i23{font-weight:bold;font-size:20px;line-height:140%;margin-bottom:0px;}
.s1hgqko5{border-radius:6px;color:#f16122;padding:0 13px;font-weight:bold;font-size:12px;white-space:nowrap;line-height:200%;-webkit-letter-spacing:0.06em;-moz-letter-spacing:0.06em;-ms-letter-spacing:0.06em;letter-spacing:0.06em;border:1px solid;display:flex;align-items:center;font-family:"Zen Kaku Gothic New" !important;}

.f1096hus{width:100%;padding:20px;background-color:#FFFFFF;border-radius:4px;margin-bottom:20px;}.f1096hus strong{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen, Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-style:normal;font-weight:bold;font-size:16px;line-height:140%;color:#1B1B1B;}.f1096hus p{font-style:normal;font-weight:normal;font-size:16px;line-height:190%;white-space:break-spaces;color:#1B1B1B;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen, Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;margin-bottom:0px;margin-top:10px;}@media (max-width:768px){.f1096hus p{margin-top:20px;}}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-error.ant-picker .ant-picker-active-bar {
  background: #ff7875;
}
.ant-picker-status-warning.ant-picker,
.ant-picker-status-warning.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #faad14;
}
.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-warning.ant-picker .ant-picker-active-bar {
  background: #ffc53d;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-picker-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-input > input::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-picker-input > input:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
      user-select: none;
}
.ant-picker-input > input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-picker-input > input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 0px 7px;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}
.ant-picker-large {
  padding: 6.5px 11px 6.5px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 0px 7px 0px;
}
.ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 11px;
}
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #1890ff;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 2.58561808px;
  display: block;
  transform: rotate(-135deg) translateY(1px);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 2.58561808px;
  display: block;
  transform: rotate(45deg);
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 7.54247233px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 11.3137085px;
  height: 11.3137085px;
  margin-left: 16.5px;
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-out;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-picker-range-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}
.ant-picker-panel-focused {
  border-color: #1890ff;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #1890ff;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #1890ff;
  border-radius: 2px;
  content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e6f7ff;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #1890ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #e6f7ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #7ec1ff;
  border-bottom: 1px dashed #7ec1ff;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #cbe6ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #cbe6ff;
  transition: all 0.3s;
  content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #7ec1ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent;
}
.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #1890ff;
}
.ant-picker-today-btn:hover {
  color: #40a9ff;
}
.ant-picker-today-btn:active {
  color: #096dd9;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #1890ff;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-time-panel-column-active {
  background: rgba(230, 247, 255, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e6f7ff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl {
  direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #7ec1ff;
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #7ec1ff;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #1890ff;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #1890ff;
}
.ant-tag-checkable:active {
  background-color: #096dd9;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-error {
  color: #ff4d4f;
  background: #fff2f0;
  border-color: #ffccc7;
}
.ant-tag-warning {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}

.womh7j9{margin-bottom:150px;padding:0;padding-top:88px;font-family:"Zen Kaku Gothic New";}.womh7j9 form{max-width:666px;margin:auto;}
.f1oconfa{display:flex;flex-direction:column;margin-bottom:30px;}.f1oconfa .ant-picker-disabled{background-color:#e9e9e9 !important;}@media (max-width:600px){.f1oconfa{margin-bottom:20px;}}
.fsno0bf{font-size:16px;line-height:30px;font-weight:bold;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;line-height:26px;color:#1B1B1B;margin-bottom:10px;font-weight:500;margin-left:auto;}
.csv7ob4{font-weight:500;font-size:16px;line-height:30px;margin-bottom:0;color:#1B1B1B;}
.f1r4nz02{border:var(--f1r4nz02-0);background:var(--f1r4nz02-1);width:100%;height:60px;-webkit-appearance:none;-moz-appearance:none;appearance:none;border-radius:4px;padding:10px 20px;font-weight:500;font-size:16px;line-height:30px;}
.s199j586{position:absolute;right:27px;top:25px;pointer-events:none;}
.dmeddwf{background-color:#FFFFFF;height:60px;border-width:1px;border-style:solid;border-color:#c4c4c4;border-radius:4px;padding:10px 26px 10px 20px;font-weight:500;font-size:16px;line-height:30px;color:#1B1B1B;margin-bottom:10px;}.dmeddwf input::-moz-placeholder{color:#1B1B1B;}.dmeddwf input:-ms-input-placeholder{color:#1B1B1B;}.dmeddwf input::placeholder{color:#1B1B1B;}.dmeddwf input{font-weight:500;font-size:16px;line-height:30px;}.dmeddwf .ant-picker-clear{margin-right:16px;}.dmeddwf .ant-picker-separator{margin-right:38px;}
.c1ixdt1p{font-family:YakuHanJP,Zen Kaku Gothic New,sans-serif;font-weight:400;-webkit-text-stroke:0.4px #2e2e2e;font-size:18px;line-height:34px;}.c1ixdt1p .container{display:block;position:relative;padding-left:60px;margin-bottom:12px;cursor:pointer;font-size:16px;font-weight:500;padding-top:8px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}.c1ixdt1p .text-container{display:block;position:relative;margin-bottom:12px;cursor:pointer;padding-top:8px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-family:YakuHanJP,Zen Kaku Gothic New,sans-serif;font-weight:400;-webkit-text-stroke:0.4px #2e2e2e;font-size:18px;line-height:34px;}.c1ixdt1p .container input{position:absolute;opacity:0;cursor:pointer;height:0;width:0;}.c1ixdt1p .checkmark{position:absolute;top:0;left:0;height:40px;width:40px;background-color:var(--c1ixdt1p-0);border:1px solid;border-color:var(--c1ixdt1p-1);box-sizing:border-box;border-radius:6px;}.c1ixdt1p .container:hover input ~ .checkmark{background-color:#fff;}.c1ixdt1p .container input:checked ~ .checkmark{background-color:#fff;border:2px solid #ff6b00;}.c1ixdt1p .checkmark:after{content:"";position:absolute;display:none;}.c1ixdt1p .container input:checked ~ .checkmark:after{display:block;}.c1ixdt1p .container .checkmark:after{left:14px;top:6px;width:10px;height:18px;border:solid #ff6b00;border-width:0 3px 3px 0;transform:rotate(45deg);}
.rzlnghy{display:flex;align-items:center;word-break:break-all;flex-wrap:wrap;}.rzlnghy label{gap:10px;}@media (max-width:600px){.rzlnghy{align-items:flex-start;flex-direction:column;}.rzlnghy label{margin-left:0;}.rzlnghy label:first-child{margin-bottom:12px;}}
.s1ag2t23{background-color:var(--s1ag2t23-0);border-width:1px;border-style:solid;border-color:var(--s1ag2t23-1);font-weight:500;font-size:16px;line-height:30px;width:100%;height:60px;border-radius:4px;padding:10px 20px;}.s1ag2t23::-moz-placeholder{color:#b6b6b6;}.s1ag2t23:-ms-input-placeholder{color:#b6b6b6;}.s1ag2t23::placeholder{color:#b6b6b6;}
.s15czdyb{border-width:1px;border-style:solid;background-color:var(--s15czdyb-0);border-color:var(--s15czdyb-1);resize:none;font-weight:500;font-size:16px;line-height:30px;height:210px !important;border-radius:4px;padding:10px 20px;}.s15czdyb::-moz-placeholder{color:#b6b6b6;white-space:break-spaces;}.s15czdyb:-ms-input-placeholder{color:#b6b6b6;white-space:break-spaces;}.s15czdyb::placeholder{color:#b6b6b6;white-space:break-spaces;}
.sk4ojh7{position:relative;}
.iaclxfs{position:absolute;right:20px;top:50%;transform:translateY(-50%);font-weight:500;font-size:16px;line-height:30px;margin:0;}
.cbdej6q{color:#FA5F00;-webkit-text-decoration:underline;text-decoration:underline;font-family:YakuHanJP,Zen Kaku Gothic New,sans-serif;font-weight:400;-webkit-text-stroke:0.4px #fa5f00;font-size:18px;line-height:34px;}.cbdej6q:hover{color:#ff6b00;}
.s1goddav{height:60px;width:100%;border-radius:6px;margin-top:30px;background:var(--s1goddav-0);color:#FFFFFF;border:0;font-family:"Zen Kaku Gothic New";font-style:normal;font-weight:700;font-size:18px;line-height:140%;text-align:center;cursor:pointer;}.s1goddav:disabled{cursor:not-allowed;opacity:0.7;}.s1goddav:hover{opacity:var(--s1goddav-2);}@media (max-width:600px){.s1goddav{margin-top:25px;height:50px;font-size:16px;line-height:25px;}}
.e1juck9d{font-weight:700;font-size:16px;line-height:26px;color:#ff6b00;margin-top:10px;margin-bottom:0;}
.cx7g1xw{margin-top:15px;}
.chtjfp7{padding-right:50px;}

.c16o6vx1{padding:40px;margin-bottom:20px;border-radius:10px;background-color:#FFFFFF;display:flex;justify-content:space-between;}.c16o6vx1 img{-o-object-fit:contain;object-fit:contain;}@media (max-width:550px){.c16o6vx1 img{margin:0 auto;margin-bottom:24px;width:110px;height:112px;}}@media (max-width:550px){.c16o6vx1{flex-direction:column;padding:20px;}}
.d7m8bla{margin-left:var(--d7m8bla-0);}.d7m8bla h2{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen, Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-style:normal;font-weight:bold;font-size:24px;line-height:140%;margin-bottom:20px;color:#1B1B1B;}@media (max-width:769px){.d7m8bla h2{font-size:22px;}}.d7m8bla p{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen, Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-style:normal;font-weight:500;font-size:16px;line-height:30px;color:#1B1B1B;margin-bottom:20px;white-space:break-spaces;}@media (max-width:769px){.d7m8bla p{font-weight:normal;}}@media (max-width:769px){.d7m8bla{margin:0px;}}

.w10y7n2d{background-color:#001529;position:sticky;top:0px;}.w10y7n2d ul{border-right:none;}.w10y7n2d li{width:100% !important;}.w10y7n2d li:after{border-right:none !important;}.w10y7n2d .ant-menu{background:#001529;height:calc(100vh - 79px);}.w10y7n2d .ant-menu-item,.w10y7n2d .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{color:#FFFFFF;background:#001529;opacity:0.65;}.w10y7n2d .active,.w10y7n2d .ant-menu-item:hover{background:#b7b7b7 !important;color:#001529 !important;}
.s1t537iv{padding:14px;background:#001529;text-align:center;cursor:pointer;border-bottom:1px solid rgba(255,255,255,0.5);}.s1t537iv img{width:100px;}

.t1t99vmn{font-weight:500;font-size:16px;line-height:200%;color:#f16122;}

.sqzfvfa{border:var(--sqzfvfa-0);background:var(--sqzfvfa-1);width:100%;border-radius:6px;padding:20px 30px;line-height:1px;font-weight:500;font-size:16px;}.sqzfvfa:disabled{background:#e5e5e5;cursor:not-allowed;}

.m1t1iekx{overflow:hidden;padding-bottom:var(--m1t1iekx-0);}@media (max-width:800px){.m1t1iekx{padding-bottom:75px;}}
.c1507i9o{max-width:700px;margin:auto;position:relative;}@media (max-width:1242px){.c1507i9o{overflow:hidden;padding-left:40px;padding-right:40px;}}@media (max-width:370px){.c1507i9o{padding-left:20px;padding-right:20px;}}
.s1nblrml{font-weight:bold;font-size:18px;line-height:160%;-webkit-letter-spacing:0.03em;-moz-letter-spacing:0.03em;-ms-letter-spacing:0.03em;letter-spacing:0.03em;color:#1b1b1b;background:#fff;position:relative;display:flex;}
.h9vrayj{font-weight:normal !important;font-size:18px;line-height:normal;-webkit-letter-spacing:0.03em;-moz-letter-spacing:0.03em;-ms-letter-spacing:0.03em;letter-spacing:0.03em;color:#1b1b1b;background:#fff;position:relative;padding-bottom:12px;}.h9vrayj h1{font-weight:700;font-size:30px;line-height:42px;}@media (max-width:768px){.h9vrayj h1{font-size:22px;line-height:30.8px;}}
.sf8r9rj{flex:1 1;margin-top:90px;color:#1b1b1b;}.sf8r9rj .button-wrapper{display:flex;justify-content:center;}.sf8r9rj > div{margin-bottom:30px;}@media (max-width:600px){.sf8r9rj{padding:0px;}.sf8r9rj > div{margin-bottom:20px;}}@media (max-width:545px){.sf8r9rj{margin-top:51px;}}
.s3oas8r{border:var(--s3oas8r-0);background:var(--s3oas8r-1);width:100%;-webkit-appearance:none;-moz-appearance:none;appearance:none;border-radius:6px;padding:20px 30px;line-height:1;font-weight:500;font-size:16px;}
.s4xz3vk{width:100%;padding:18px 30px;border-radius:4px;line-height:1.5;font-weight:500;font-size:16px;background:var(--s4xz3vk-0);border:var(--s4xz3vk-1);min-height:210px;}
.sgb0du9{font-weight:700;font-size:20px;line-height:200%;text-align:center;-webkit-letter-spacing:0.06em;-moz-letter-spacing:0.06em;-ms-letter-spacing:0.06em;letter-spacing:0.06em;color:#ffffff;max-width:520px;border:none;background:var(--sgb0du9-0);width:100%;border-radius:1000px;padding:8px;cursor:pointer;}.sgb0du9:hover{opacity:0.7;}@media (max-width:600px){.sgb0du9{font-size:16px;}}
.c11zvrz4 .container{display:block;font-weight:500;position:relative;padding-left:60px;margin-bottom:12px;cursor:pointer;font-size:16px;padding-top:8px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}.c11zvrz4 .container input{position:absolute;opacity:0;cursor:pointer;height:0;width:0;}.c11zvrz4 .checkmark{position:absolute;top:0;left:0;height:40px;width:40px;background-color:var(--c11zvrz4-0);border:1px solid;border-color:var(--c11zvrz4-1);box-sizing:border-box;border-radius:6px;}.c11zvrz4 .container:hover input ~ .checkmark{background-color:#fff;}.c11zvrz4 .container input:checked ~ .checkmark{background-color:#fff;border:2px solid #ff6b00;}.c11zvrz4 .checkmark:after{content:"";position:absolute;display:none;}.c11zvrz4 .container input:checked ~ .checkmark:after{display:block;}.c11zvrz4 .container .checkmark:after{left:14px;top:6px;width:10px;height:18px;border:solid #ff6b00;border-width:0 3px 3px 0;transform:rotate(45deg);}
.s1vxwegk{color:#fa5f00;-webkit-text-decoration:underline;text-decoration:underline;}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #1890ff;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #40a9ff;
}
.ant-page-header-back-button:active {
  color: #096dd9;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  white-space: unset;
}
.ant-page-header-content {
  padding-top: 12px;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar .ant-image-img {
  display: block;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-square {
  border-radius: 2px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ant-avatar-group {
  display: inline-flex;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.ant-popover-content {
  position: relative;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 15.3137085px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 15.3137085px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8.0005px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-popover-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(11px) rotate(-135deg);
}
.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(-45deg);
}
.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-pink .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-pink .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-red .ant-popover-inner {
  background-color: #f5222d;
}
.ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}
.ant-popover-volcano .ant-popover-inner {
  background-color: #fa541c;
}
.ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}
.ant-popover-orange .ant-popover-inner {
  background-color: #fa8c16;
}
.ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}
.ant-popover-yellow .ant-popover-inner {
  background-color: #fadb14;
}
.ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}
.ant-popover-gold .ant-popover-inner {
  background-color: #faad14;
}
.ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}
.ant-popover-cyan .ant-popover-inner {
  background-color: #13c2c2;
}
.ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}
.ant-popover-lime .ant-popover-inner {
  background-color: #a0d911;
}
.ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}
.ant-popover-green .ant-popover-inner {
  background-color: #52c41a;
}
.ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}
.ant-popover-blue .ant-popover-inner {
  background-color: #1890ff;
}
.ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}
.ant-popover-geekblue .ant-popover-inner {
  background-color: #2f54eb;
}
.ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}
.ant-popover-purple .ant-popover-inner {
  background-color: #722ed1;
}
.ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-right: 22px;
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-rtl {
  direction: rtl;
}

.p1p6izcw{background:#FFFFFF;z-index:1000;position:sticky;top:0px;height:64px;min-height:64px;box-shadow:0px 0px 2px 0px #3535352e;display:flex;align-items:center;padding:0px 24px;}.p1p6izcw svg{fill:#262626;}.p1p6izcw .ant-page-header-heading{width:100%;}.p1p6izcw .ant-page-header-heading-title{font-size:20px;font-weight:normal;line-height:28px;color:#262626;}.p1p6izcw .ant-page-header-heading-extra{margin-left:auto;}

.c1aitc0y{max-width:500px;width:100%;margin-bottom:30px;display:flex;align-items:flex-start;justify-content:space-between;}.c1aitc0y img{-o-object-fit:contain;object-fit:contain;}
.d1pl6rx8{margin-left:20px;max-width:380px;}.d1pl6rx8 h2{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen, Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-style:normal;font-weight:bold;font-size:16px;line-height:140%;color:#1B1B1B;}.d1pl6rx8 p{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen, Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-style:normal;font-weight:500;font-size:16px;line-height:190%;margin-bottom:10px;color:#1B1B1B;}
.b1m5ki72{display:flex;justify-content:flex-end;}@media (max-width:550px){.b1m5ki72{justify-content:center;}}.b1m5ki72 a{display:flex;align-items:center;font-style:normal;font-weight:bold;font-size:14px;line-height:130%;color:#fa5f00;}.b1m5ki72 a span{margin-right:7px;}
.i1tsoraq{height:100px;width:100px;border-radius:50%;}

.w11adlg9{background-color:#00A0D2;}
.c1vtmtph{max-width:1000px;display:flex;padding:46px 0;flex-wrap:wrap;}.c1vtmtph a,.c1vtmtph p,.c1vtmtph h2,.c1vtmtph h5{color:#FFFFFF;}.c1vtmtph a{transition:opacity 0.5s;}.c1vtmtph a:hover{opacity:0.8;}.c1vtmtph > :first-child{padding-left:0px;}.c1vtmtph > :last-child{padding-right:0px;}@media (max-width:1100px){.c1vtmtph{padding:46px 60px;}}@media (min-width:768px){.c1vtmtph{justify-content:center;}}@media (min-width:1100px){.c1vtmtph{margin:auto;}}@media (min-width:925px){.c1vtmtph{justify-content:flex-start;}}@media (max-width:768px){.c1vtmtph{justify-content:space-evenly;padding:30px 20px 30px 20px;}.c1vtmtph > *{padding:20px 0px !important;}}@media (max-width:625px){.c1vtmtph{flex-direction:column;}}
.cimkale{display:flex;flex-direction:column;padding:16px;}.cimkale a{font-weight:500;font-size:14px;line-height:22px;display:flex;align-items:center;}.cimkale a span{margin-right:11px;}.cimkale svg{font-size:12px;}
.c1l55gbz{width:262px;}
.lxbhlsb{margin-bottom:4px;}
.szdmfd3{font-weight:bold;font-size:16px;line-height:22px;margin-bottom:20px;margin-top:var(--szdmfd3-0);}

.s1o4ynl3{min-width:256px !important;max-width:256px !important;flex:0 0 256px !important;}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-image-mask-info .anticon {
  margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.ant-zoom-enter,
.ant-image-preview.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-progress {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled {
  color: rgba(255, 255, 255, 0.25);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 10px;
}
.ant-image-preview-switch-right {
  right: 10px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #1890ff;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-inline-end: 4px;
  padding-inline-start: 8px;
  padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-inline-start: 7px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  margin-inline-start: 3px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #faad14 !important;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-clear,
.ant-select-status-warning.ant-select-has-feedback .ant-select-clear,
.ant-select-status-success.ant-select-has-feedback .ant-select-clear,
.ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
  right: 32px;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
  padding-right: 42px;
}
/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-select-selection-item {
  flex: 1 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-arrow > *:not(:last-child) {
  margin-inline-end: 8px;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear::before {
  display: block;
}
.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #e6f7ff;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #1890ff;
}
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #f5f5f5;
}
.ant-select-item-option-grouped {
  padding-left: 24px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select.ant-select-in-form-item {
  width: 100%;
}
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
  right: auto;
  right: initial;
  left: 11px;
}
.ant-select-rtl .ant-select-clear {
  right: auto;
  right: initial;
  left: 11px;
}
.ant-select-dropdown-rtl {
  direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url('#linearGradient-1');
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}
.ant-input-affix-wrapper::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-input-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
      user-select: none;
}
.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #40a9ff;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none !important;
}
.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-prefix > *:not(:last-child),
.ant-input-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-input-show-count-suffix {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-show-count-has-suffix {
  margin-right: 2px;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.anticon.ant-input-clear-icon:hover,
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.anticon.ant-input-clear-icon:active,
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85);
}
.anticon.ant-input-clear-icon-hidden,
.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.anticon.ant-input-clear-icon-has-suffix,
.ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}
.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}
.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
      user-select: none;
}
.ant-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 0px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #1890ff;
}
.ant-input-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon.anticon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon.anticon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input[type='color'] {
  height: 32px;
}
.ant-input[type='color'].ant-input-lg {
  height: 40px;
}
.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}
.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  margin-bottom: -22px;
}
.ant-input-textarea-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #40a9ff;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #40a9ff;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button {
  height: 32px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 40px;
}
.ant-input-search-small .ant-input-search-button {
  height: 24px;
}
.ant-input-group-wrapper-rtl {
  direction: rtl;
}
.ant-input-group-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl {
  direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #40a9ff;
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #40a9ff;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }
  .ant-input-lg {
    height: 40px;
  }
  .ant-input-sm {
    height: 24px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
  min-width: 0;
}
.ant-form-horizontal .ant-form-item-label[class$='-24'] + .ant-form-item-control,
.ant-form-horizontal .ant-form-item-label[class*='-24 '] + .ant-form-item-control {
  min-width: unset;
}
.ant-form-vertical .ant-form-item {
  flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: normal;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain-error {
  color: #ff4d4f;
}
.ant-form-item-explain-warning {
  color: #faad14;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14;
}
.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 24px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}
.ant-form-large .ant-form-item-label > label {
  height: 40px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
  transition: margin-bottom 0.3s 0.017s linear;
}
.ant-form-item-with-help {
  margin-bottom: 0;
  transition: none;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.3215em;
  white-space: unset;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  -ms-writing-mode: lr-tb;
      writing-mode: horizontal-tb;
  margin-inline-start: 4px;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-item-explain-connected {
  height: 0;
  min-height: 0;
  opacity: 0;
}
.ant-form-item-extra {
  min-height: 24px;
}
.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  min-height: 24px;
  opacity: 1;
}
.ant-form-item-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-feedback-icon-success {
  color: #52c41a;
}
.ant-form-item-feedback-icon-error {
  color: #ff4d4f;
}
.ant-form-item-feedback-icon-warning {
  color: #faad14;
}
.ant-form-item-feedback-icon-validating {
  color: #1890ff;
}
.ant-show-help {
  transition: height 0.3s linear, min-height 0.3s linear, margin-bottom 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-show-help-leave {
  min-height: 24px;
}
.ant-show-help-leave-active {
  min-height: 0;
}
.ant-show-help-item {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-appear,
.ant-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.ant-show-help-item-appear-active,
.ant-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.ant-show-help-item-leave-active {
  transform: translateY(-5px);
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-row {
  display: flex;
  flex-flow: row wrap;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-space-evenly {
  justify-content: space-evenly;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  font-size: 0;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}
.ant-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type='radio'] {
  width: 14px;
  height: 14px;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1890ff;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #1890ff;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1890ff;
  background: #fff;
  border-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #40a9ff;
  border-color: #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #096dd9;
  border-color: #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #40a9ff;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #096dd9;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 22.001px;
  line-height: 1;
  vertical-align: top;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-upload-list-item-card-actions:hover .anticon {
  color: rgba(0, 0, 0, 0.85);
}
.ant-upload-list-item-info {
  height: 100%;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff2f0;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  margin-bottom: 12px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}

.mq7z09r{background-color:#ffffff;margin:24px;padding:16px;}.mq7z09r .ant-input-number{width:100%;}.mq7z09r .ant-upload-list-item{padding:0px;}.mq7z09r .ant-upload img{-o-object-fit:cover;object-fit:cover;}.mq7z09r .ant-upload-drag{margin-bottom:20px;}.mq7z09r .ant-upload-drag .ant-image{margin-right:10px;}.mq7z09r .ant-upload-drag img{width:100px;height:100px;}.mq7z09r .ant-upload-drag > span{padding:16px !important;}
.et7j23x{color:#ff6b00;font-size:12px;}
.c163ysa9{display:flex;max-width:500px;justify-content:space-between;}
.s1uvraon{width:200px;}
.g95a1ox{display:flex;flex-wrap:wrap;}.g95a1ox img{margin-right:10px;margin-bottom:10px;}
.u17ze191{display:flex;}.u17ze191 .avatar-uploader{width:unset;}.u17ze191 .ant-upload{margin-bottom:0px;}.u17ze191 button{margin-top:auto;}
.uksz1nc{width:100px;height:100px;border:1px dashed #d9d9d9;display:flex;align-items:center;justify-content:center;flex-direction:column;}.uksz1nc:hover{border-color:#40a9ff;}.uksz1nc > span{font-size:20px !important;}
.gqazmyk{position:relative;}.gqazmyk button{position:absolute;right:5px;top:-10px;background:#0000009e;border:none;cursor:pointer;}

.m1pq1mpz{background-color:#FFFFFF;margin:24px;padding:16px;}
.e993ikw{color:#ff6b00;font-size:12px;}

body {
  overflow: hidden;
}

body > div:first-child {
  height: 100%;
}
.ant-layout {
  height: 100%;
}

.ant-image-preview-operations {
  pointer-events: none;
}

.ant-image-preview-operations > li:not(:first-child) {
  display: none;
}

.ant-image-preview-operations > li:first-child {
  pointer-events: all;
}

.lpg0y66{width:100%;overflow-x:auto;background-color:#f0f2f5;}.lpg0y66 .ant-layout-header{height:54px;padding:0 50px;color:rgba(0,0,0,0.85);line-height:64px;background:#ffffff;box-shadow:0px 4px 4px rgba(0,0,0,0.1);}.lpg0y66 .ant-layout-content{padding:30px;}

