.c1j5hjy6{display:inline-block;width:200px;max-width:var(--c1j5hjy6-0);cursor:pointer;-webkit-transition:opacity 0.5s;transition:opacity 0.5s;}.c1j5hjy6 img{width:var(--c1j5hjy6-0) !important;height:var(--c1j5hjy6-0) !important;object-fit:cover;border-radius:10px;margin-bottom:10px;}.c1j5hjy6 .ant-skeleton-image{width:200px;height:200px;border-radius:10px;}.c1j5hjy6:hover{opacity:0.8;}
.c1vyakym{margin:var(--c1vyakym-0);color:#00a0d2;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:14px;font-weight:bold;line-height:18px;word-break:break-all;}
.c1k0cf7u{margin:var(--c1k0cf7u-0);font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:14px;font-weight:bold;line-height:18px;color:#1B1B1B;word-break:break-all;}
.cxp6hg8{margin-bottom:0;font-size:12px;line-height:22px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:bold;line-height:20px;color:#1B1B1B;word-break:break-all;}
.c1fswnpe{margin-bottom:0;font-size:12px;line-height:22px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:bold;line-height:20px;color:#b6b6b6;word-break:break-all;}
.o1d78svk{border-radius:10px;overflow:hidden;position:relative;}.o1d78svk::after{content:"";height:56px;position:absolute;right:0;left:0;bottom:0;background:linear-gradient(180deg,rgba(27,27,27,0) 0%,#1b1b1b 100%);}
.o16isyx5{position:absolute;z-index:10;bottom:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;padding:10px 10px 10px 15px;}
.ouprej5{font-size:12px;line-height:22px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:bold;color:#F5F5F5;margin-right:auto;}
.o1lzokr9{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:14px;font-weight:bold;line-height:18px;font-weight:500;color:#FFFFFF;line-height:22px;}
.o12fl6tc{font-size:16px;line-height:25px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;color:#FFFFFF;font-weight:bold;text-align:right;margin-left:4px;}
.f1w9761{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.c1ogzuq4{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:14px;line-height:22px;color:#1B1B1B;margin-bottom:10px;font-weight:500;word-break:break-all;}
.cblewx{font-size:12px;line-height:22px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:bold;margin:0;color:#b6b6b6;}
.h4i2eso{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:66px;background:#00a0d2;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-size:12px;line-height:22px;color:#FFFFFF;border-radius:2px;}
.c1khbxnk{font-size:12px;line-height:22px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;font-weight:bold;margin:0;color:#b6b6b6;}
