.womh7j9{margin-bottom:150px;padding:0;padding-top:88px;font-family:"Zen Kaku Gothic New";}.womh7j9 form{max-width:666px;margin:auto;}
.f1oconfa{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-bottom:30px;}.f1oconfa .ant-picker-disabled{background-color:#e9e9e9 !important;}@media (max-width:600px){.f1oconfa{margin-bottom:20px;}}
.fsno0bf{font-size:16px;line-height:30px;font-weight:bold;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;line-height:26px;color:#1B1B1B;margin-bottom:10px;font-weight:500;margin-left:auto;}
.csv7ob4{font-weight:500;font-size:16px;line-height:30px;margin-bottom:0;color:#1B1B1B;}
.f1r4nz02{border:var(--f1r4nz02-0);background:var(--f1r4nz02-1);width:100%;height:60px;-webkit-appearance:none;-moz-appearance:none;appearance:none;border-radius:4px;padding:10px 20px;font-weight:500;font-size:16px;line-height:30px;}
.s199j586{position:absolute;right:27px;top:25px;pointer-events:none;}
.dmeddwf{background-color:#FFFFFF;height:60px;border-width:1px;border-style:solid;border-color:#c4c4c4;border-radius:4px;padding:10px 26px 10px 20px;font-weight:500;font-size:16px;line-height:30px;color:#1B1B1B;margin-bottom:10px;}.dmeddwf input::-webkit-input-placeholder{color:#1B1B1B;}.dmeddwf input::-moz-placeholder{color:#1B1B1B;}.dmeddwf input:-ms-input-placeholder{color:#1B1B1B;}.dmeddwf input::placeholder{color:#1B1B1B;}.dmeddwf input{font-weight:500;font-size:16px;line-height:30px;}.dmeddwf .ant-picker-clear{margin-right:16px;}.dmeddwf .ant-picker-separator{margin-right:38px;}
.c1ixdt1p{font-family:YakuHanJP,Zen Kaku Gothic New,sans-serif;font-weight:400;-webkit-text-stroke:0.4px #2e2e2e;font-size:18px;line-height:34px;}.c1ixdt1p .container{display:block;position:relative;padding-left:60px;margin-bottom:12px;cursor:pointer;font-size:16px;font-weight:500;padding-top:8px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}.c1ixdt1p .text-container{display:block;position:relative;margin-bottom:12px;cursor:pointer;padding-top:8px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-family:YakuHanJP,Zen Kaku Gothic New,sans-serif;font-weight:400;-webkit-text-stroke:0.4px #2e2e2e;font-size:18px;line-height:34px;}.c1ixdt1p .container input{position:absolute;opacity:0;cursor:pointer;height:0;width:0;}.c1ixdt1p .checkmark{position:absolute;top:0;left:0;height:40px;width:40px;background-color:var(--c1ixdt1p-0);border:1px solid;border-color:var(--c1ixdt1p-1);box-sizing:border-box;border-radius:6px;}.c1ixdt1p .container:hover input ~ .checkmark{background-color:#fff;}.c1ixdt1p .container input:checked ~ .checkmark{background-color:#fff;border:2px solid #ff6b00;}.c1ixdt1p .checkmark:after{content:"";position:absolute;display:none;}.c1ixdt1p .container input:checked ~ .checkmark:after{display:block;}.c1ixdt1p .container .checkmark:after{left:14px;top:6px;width:10px;height:18px;border:solid #ff6b00;border-width:0 3px 3px 0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);}
.rzlnghy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;word-break:break-all;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}.rzlnghy label{gap:10px;}@media (max-width:600px){.rzlnghy{-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.rzlnghy label{margin-left:0;}.rzlnghy label:first-child{margin-bottom:12px;}}
.s1ag2t23{background-color:var(--s1ag2t23-0);border-width:1px;border-style:solid;border-color:var(--s1ag2t23-1);font-weight:500;font-size:16px;line-height:30px;width:100%;height:60px;border-radius:4px;padding:10px 20px;}.s1ag2t23::-webkit-input-placeholder{color:#b6b6b6;}.s1ag2t23::-moz-placeholder{color:#b6b6b6;}.s1ag2t23:-ms-input-placeholder{color:#b6b6b6;}.s1ag2t23::placeholder{color:#b6b6b6;}
.s15czdyb{border-width:1px;border-style:solid;background-color:var(--s15czdyb-0);border-color:var(--s15czdyb-1);resize:none;font-weight:500;font-size:16px;line-height:30px;height:210px !important;border-radius:4px;padding:10px 20px;}.s15czdyb::-webkit-input-placeholder{color:#b6b6b6;white-space:break-spaces;}.s15czdyb::-moz-placeholder{color:#b6b6b6;white-space:break-spaces;}.s15czdyb:-ms-input-placeholder{color:#b6b6b6;white-space:break-spaces;}.s15czdyb::placeholder{color:#b6b6b6;white-space:break-spaces;}
.sk4ojh7{position:relative;}
.iaclxfs{position:absolute;right:20px;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);font-weight:500;font-size:16px;line-height:30px;margin:0;}
.cbdej6q{color:#FA5F00;-webkit-text-decoration:underline;text-decoration:underline;font-family:YakuHanJP,Zen Kaku Gothic New,sans-serif;font-weight:400;-webkit-text-stroke:0.4px #fa5f00;font-size:18px;line-height:34px;}.cbdej6q:hover{color:#ff6b00;}
.s1goddav{height:60px;width:100%;border-radius:6px;margin-top:30px;background:var(--s1goddav-0);color:#FFFFFF;border:0;font-family:"Zen Kaku Gothic New";font-style:normal;font-weight:700;font-size:18px;line-height:140%;text-align:center;cursor:pointer;}.s1goddav:disabled{cursor:not-allowed;opacity:0.7;}.s1goddav:hover{opacity:var(--s1goddav-2);}@media (max-width:600px){.s1goddav{margin-top:25px;height:50px;font-size:16px;line-height:25px;}}
.e1juck9d{font-weight:700;font-size:16px;line-height:26px;color:#ff6b00;margin-top:10px;margin-bottom:0;}
.cx7g1xw{margin-top:15px;}
.chtjfp7{padding-right:50px;}
