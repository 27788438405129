.m1t1iekx{overflow:hidden;padding-bottom:var(--m1t1iekx-0);}@media (max-width:800px){.m1t1iekx{padding-bottom:75px;}}
.c1507i9o{max-width:700px;margin:auto;position:relative;}@media (max-width:1242px){.c1507i9o{overflow:hidden;padding-left:40px;padding-right:40px;}}@media (max-width:370px){.c1507i9o{padding-left:20px;padding-right:20px;}}
.s1nblrml{font-weight:bold;font-size:18px;line-height:160%;-webkit-letter-spacing:0.03em;-moz-letter-spacing:0.03em;-ms-letter-spacing:0.03em;letter-spacing:0.03em;color:#1b1b1b;background:#fff;position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.h9vrayj{font-weight:normal !important;font-size:18px;line-height:normal;-webkit-letter-spacing:0.03em;-moz-letter-spacing:0.03em;-ms-letter-spacing:0.03em;letter-spacing:0.03em;color:#1b1b1b;background:#fff;position:relative;padding-bottom:12px;}.h9vrayj h1{font-weight:700;font-size:30px;line-height:42px;}@media (max-width:768px){.h9vrayj h1{font-size:22px;line-height:30.8px;}}
.sf8r9rj{-webkit-flex:1;-ms-flex:1;flex:1;margin-top:90px;color:#1b1b1b;}.sf8r9rj .button-wrapper{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.sf8r9rj > div{margin-bottom:30px;}@media (max-width:600px){.sf8r9rj{padding:0px;}.sf8r9rj > div{margin-bottom:20px;}}@media (max-width:545px){.sf8r9rj{margin-top:51px;}}
.s3oas8r{border:var(--s3oas8r-0);background:var(--s3oas8r-1);width:100%;-webkit-appearance:none;-moz-appearance:none;appearance:none;border-radius:6px;padding:20px 30px;line-height:1;font-weight:500;font-size:16px;}
.s4xz3vk{width:100%;padding:18px 30px;border-radius:4px;line-height:1.5;font-weight:500;font-size:16px;background:var(--s4xz3vk-0);border:var(--s4xz3vk-1);min-height:210px;}
.sgb0du9{font-weight:700;font-size:20px;line-height:200%;text-align:center;-webkit-letter-spacing:0.06em;-moz-letter-spacing:0.06em;-ms-letter-spacing:0.06em;letter-spacing:0.06em;color:#ffffff;max-width:520px;border:none;background:var(--sgb0du9-0);width:100%;border-radius:1000px;padding:8px;cursor:pointer;}.sgb0du9:hover{opacity:0.7;}@media (max-width:600px){.sgb0du9{font-size:16px;}}
.c11zvrz4 .container{display:block;font-weight:500;position:relative;padding-left:60px;margin-bottom:12px;cursor:pointer;font-size:16px;padding-top:8px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}.c11zvrz4 .container input{position:absolute;opacity:0;cursor:pointer;height:0;width:0;}.c11zvrz4 .checkmark{position:absolute;top:0;left:0;height:40px;width:40px;background-color:var(--c11zvrz4-0);border:1px solid;border-color:var(--c11zvrz4-1);box-sizing:border-box;border-radius:6px;}.c11zvrz4 .container:hover input ~ .checkmark{background-color:#fff;}.c11zvrz4 .container input:checked ~ .checkmark{background-color:#fff;border:2px solid #ff6b00;}.c11zvrz4 .checkmark:after{content:"";position:absolute;display:none;}.c11zvrz4 .container input:checked ~ .checkmark:after{display:block;}.c11zvrz4 .container .checkmark:after{left:14px;top:6px;width:10px;height:18px;border:solid #ff6b00;border-width:0 3px 3px 0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);}
.s1vxwegk{color:#fa5f00;-webkit-text-decoration:underline;text-decoration:underline;}
