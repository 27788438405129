body {
  overflow: hidden;
}

body > div:first-child {
  height: 100%;
}
.ant-layout {
  height: 100%;
}

.ant-image-preview-operations {
  pointer-events: none;
}

.ant-image-preview-operations > li:not(:first-child) {
  display: none;
}

.ant-image-preview-operations > li:first-child {
  pointer-events: all;
}
